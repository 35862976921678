import styled from 'styled-components'
import media from 'styled-media-query'

export const StyledBackground = styled.div`
  background: linear-gradient(to bottom,#f9fbfd 0,#fff 100%);
`
export const ContainerText = styled.div`
/* background: linear-gradient(to bottom,#f9fbfd 0,#fff 100%); */

  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  margin: 20px 0;

  h2 {
    max-width: 60%;
    min-width: 300px;
    ${media.lessThan('small')`
      font-size: 25px;
    `}
  }

  p {
    margin-top: 15px;
    max-width: 50%;
    min-width: 300px;
    font-size: 20px;
  }
`
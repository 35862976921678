import React from "react"

import { Container, Row } from 'reactstrap'
import SEO from "../components/seo"

import Layout from "../components/Layout"
import Slider from '../components/Slider'
import Card from '../components/Card'
import GalleryPhotos from '../components/GalleryPhotos'

import * as S from '../styles/index'

export default () => (
  <Layout>
    <SEO title="Home" description="Imaginopet - Diagnóstico por Imagem" />
    <Slider/>
    <Container className="pt-4">
      <S.ContainerText>
        <h2>Ultrassonografia e Ecodopplercardiografia em Goiânia</h2>
        <p>Fornecemos serviço volante de atendimento ultrassonográfico às clínica veterinárias de Goiânia e região</p>
      </S.ContainerText>
    </Container>
    <Container className="py-5">
      <Row>
        <Card image="about" title="Nossa História" text="Conheça mais um pouco sobre nós!" button="Veja mais" to="/quem-somos"/>
        <Card image="service" title="O que fazemos?" text="Nossos serviços" button="Veja mais" to="/servicos"/>
        <Card image="contact" title="Agende!" text="Agende seu exame agora!" button="Agende já" to="/fale-conosco"/>
      </Row>
    </Container>
    <GalleryPhotos />
  </Layout>
)

import styled from 'styled-components'
import Img from 'gatsby-image'

export const ImageWrapper = styled(Img)`
  /* margin: ${props => props.margin+'px'}; */
  /* margin: 30px; */
  /* padding: 30px; */
  /* border-radius: 50%;
  height: 3.75rem;
  margin: auto;
  width: 3.75rem;

   
  */
`

export const Title = styled.h4`
  color: #409c8c;
`

export const Text = styled.span`
  color: #5F5E61;
  margin: 15px 0;
`
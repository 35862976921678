import React from "react";
import Slider from "react-slick";
import { useStaticQuery, graphql } from "gatsby"

import '../../../node_modules/slick-carousel/slick/slick.css';
import '../../../node_modules/slick-carousel/slick/slick-theme.css';

import * as S from './styled'

export default () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 4000,
    autoplaySpeed: 4000,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    appendDots: dots => (
      <div
        style={{
          // backgroundColor: "#ddd",
          position: "relative",
          bottom: "35px",
        }}
      >
        <ul style={{ margin: "0px" }}> {dots} </ul>
      </div>
    ),
  };
  const { slide1, slide2, slide3 } = useStaticQuery(
    graphql`
      query {
        slide1: file(relativePath: {eq: "slide-1.png"}) {
          childImageSharp {
            fluid(maxWidth: 2560) {
              ...GatsbyImageSharpFluid
            }
          }
        },
        slide2: file(relativePath: {eq: "slide-2.png"}) {
          childImageSharp {
            fluid(maxWidth: 2560) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        },
        slide3: file(relativePath: {eq: "slide-3.png"}) {
          childImageSharp {
            fluid(maxWidth: 2560) {
              ...GatsbyImageSharpFluid_withWebp_tracedSVG
            }
          }
        },
      }
    `
  )

  // let {title, author} = sideMetadata.site.siteMetadata
  return (
    <S.SliderContainer>
      <Slider {...settings}>
        <S.SlideWrapper fluid={slide1.childImageSharp.fluid} />
        <S.SlideWrapper fluid={slide2.childImageSharp.fluid} />
        <S.SlideWrapper fluid={slide3.childImageSharp.fluid} />
      </Slider>
    </S.SliderContainer>
  )
}

import React from 'react';
import { useStaticQuery, graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { Col } from 'reactstrap'

import Button from '../Button'
import Box from '../Box'

import * as S from './styled'

const Card = ({image, title, text, button, to}) => {

  const { icons } = useStaticQuery(
    graphql`
      query {
        icons: allFile(filter: { name: {regex: "/card-/"}}) {
          edges {
            node {
              name
              childImageSharp {
                fixed(width: 100, height: 100) {
                  ...GatsbyImageSharpFixed_withWebp_tracedSVG
                }
              }
            }
          }
          
        }
      }
    `
  )

  // console.log(icons);
  const icon = icons.edges.filter((item) => {
    // console.log(item.node.name)
    return item.node.name === `card-${image}`
  });
  // console.log(icon);
  
  return (
    <Col>
      <Box>
        <S.ImageWrapper fixed={icon[0].node.childImageSharp.fixed} />
        <S.Title>{title}</S.Title>
        <S.Text>{text}</S.Text>
        <Button to={to} className="btn btn-primary btn-lg">
          {button}
        </Button>
      </Box>
    </Col>
  );
}

Card.defaultProps = {
  image: "about",
  title: "title",
  text: "text",
}

Card.propTypes = {
  image: PropTypes.string,
  title: PropTypes.string,
  text: PropTypes.string,
}

export default Card;
import React from 'react'
// import PropTypes from 'prop-types'

import { Gallery } from "gatsby-gallery-simple";

import * as S from './styled'

const GalleryPhotos = () => {
  return (
    <S.Div>
      <Gallery />
    </S.Div>
  )
}

// GalleryPhotos.propTypes = {

// }

export default GalleryPhotos

